import {SelectionValues} from '../consts/global-vars';

export const getDriverString = (
  predictorForm: SelectionValues,
  driver: string,
  title: string,
  abbreviatedPosition: string,
) => {
  if (abbreviatedPosition === 'Pole') {
    const poleSeconds = predictorForm.PoleTime
      ? Number(predictorForm.PoleTime) % 60
      : '';
    const poleMinutes =
      predictorForm.PoleTime && poleSeconds
        ? (Number(predictorForm.PoleTime) - poleSeconds) / 60
        : '';
    const poleTime = `${
      poleMinutes < 10 ? `0${poleMinutes.toString()}` : poleMinutes.toString()
    } : ${
      poleSeconds < 10 ? `0${poleSeconds.toString()}` : poleSeconds.toString()
    }`;

    // eslint-disable-next-line no-nested-ternary
    const newButtonString = driver
      ? poleSeconds && poleMinutes && abbreviatedPosition === 'Pole'
        ? `${abbreviatedPosition}: ${driver}  -  ${poleTime}`
        : `${abbreviatedPosition}: ${driver}`
      : title;
    return newButtonString;
  }
  const newButtonString = driver ? `${abbreviatedPosition}: ${driver}` : title;
  return newButtonString;
};
