import React from 'react';
import Typist from 'react-typist';
import {ModalComponent} from './modal-template/modal-component';
import {useModalContext} from '../../context/modal-context';
import {theme} from '../../theme/theme';
import {Box} from '../common/box';
import {Text} from '../common/text';
import {Button} from '../common/button';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}

export const MessageModal = (props: Props) => {
  const {
    show,
    setShowModal,
    animate,
    setAnimateModal,
    message,
  } = useModalContext();

  const showMessageModal = (val: boolean) => {
    setShowModal({...show, messageModal: val});
  };
  const animateMessageModal = (val: boolean) => {
    setAnimateModal({...animate, messageModal: val});
  };
  const endTyping = () => {
    setTimeout(() => {
      animateMessageModal(false);
    }, 500);
  };

  return (
    <>
      {show.messageModal ? (
        <ModalComponent
          show={show.messageModal}
          animate={animate.messageModal}
          setAnimate={animateMessageModal}
          backgroundColor={props.backgroundColor || 'white'}
          toggle={showMessageModal}
          modalSize={{
            height: theme.sizes.normalModalHeight,
            width: theme.sizes.normalModalWidth,
          }}
        >
          <Box position="absolute" top="10px" right="10px">
            <Button
              type="button"
              tabIndex={0}
              onKeyDown={() => {
                animateMessageModal(false);
              }}
              onClick={() => {
                animateMessageModal(false);
              }}
              variant="closeButton"
            >
              &#215;
            </Button>
          </Box>
          <Box p="seven">
            <Typist stdTypingDelay={55} onTypingDone={() => endTyping()}>
              <Typist.Delay ms={1000} />
              {message}
            </Typist>
          </Box>
        </ModalComponent>
      ) : null}
    </>
  );
};
