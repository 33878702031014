import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  position,
  PositionProps,
  zIndex,
  ZIndexProps,
  boxShadow,
  BoxShadowProps,
  grid,
  GridProps,
} from 'styled-system';

export const Box = styled.div<
  ColorProps &
    LayoutProps &
    SpaceProps &
    FlexboxProps &
    BorderProps &
    PositionProps &
    BoxShadowProps &
    ZIndexProps &
    GridProps
>(color, layout, space, flexbox, border, position, zIndex, boxShadow, grid);
