import React from 'react';
import styled from 'styled-components';
import {useField} from 'formik';
import {Box} from './box';
import {Text} from './text';
import {theme} from '../../theme/theme';
import {ErrorMessageComponent} from './error-message';

interface Props {
  title?: string;
  name: string;
  placeholder: string;
  label: string;
  type: string;
}
const StyledTextInput = styled.input`
  font-family: ${theme.fontFamily};
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  padding: 3px 0;
  text-indent: 5px;
  border: 0.8px solid ${theme.colors.lightGrey};
  border-radius: 4px;
  &:focus {
    outline-width: 0;
  }
`;
export const TextInput = (props: Props) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Box display="flex" flexDirection="column">
        {props.title && (
          <Box my="one">
            <Text>{props.title}</Text>
          </Box>
        )}
        <Box my="one" alignItems="stretch">
          <StyledTextInput {...field} {...props} />
        </Box>
      </Box>
      <ErrorMessageComponent
        error={meta.error && meta.touched ? meta.error : ''}
      />
    </>
  );
};
