import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';

import {useModalContext} from './modal-context';
import {useGetMeQuery, GetMeQuery} from '../graphql/generated';

type MeType = GetMeQuery['getMe'];

const MeContext = createContext<{
  me: MeType | null;
  setMe: Dispatch<SetStateAction<MeType | null>>;
  // updateUserInfo: () => void;
}>({
  // updateUserInfo: () => null,
  me: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMe: () => {},
});
// JWT CLIENT TODO SEAUS https://flaviocopes.com/graphql-auth-apollo-jwt-cookies/
const MeContextProvider = ({children}: {children: ReactNode}) => {
  const {setLoading} = useModalContext();
  const [me, setMe] = useState<MeType | null>(null);
  const {data, loading} = useGetMeQuery();
  console.log(data);

  useEffect(() => {
    if (!data || !data.getMe) {
      setMe(null);
      return;
    }
    if (loading) setLoading(true);
    if (data.getMe) {
      console.log('setting me');
      setLoading(false);
      setMe(data.getMe);
    }
  }, [data, loading, me, setLoading]);

  return (
    <MeContext.Provider
      value={{
        me,
        setMe,
      }}
    >
      {children}
    </MeContext.Provider>
  );
};

const useMeContext = () => useContext(MeContext);

export {MeContextProvider, useMeContext};
