import React, {useEffect, useCallback} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import countryList from 'react-select-country-list';
import Cookies from 'js-cookie';
import {TextInput} from '../reusable-components/common/text-input';
import {Button} from '../reusable-components/common/button';
import {Box} from '../reusable-components/common/box';
import {SelectDropdown} from '../reusable-components/common/select-dropdown';
import {useModalContext} from '../context/modal-context';
import {useRegisterMutation} from '../graphql/generated';
import {useMeContext} from '../context/user';
import {getErrorInfo} from '../utils/error-handlers';

export const JWT_KEY = 'token';
export interface RegisterFormValueTypes {
  username: string;
  password: string;
  email: string;
  passwordCheck: string;
  countryId: string;
}
const initialRegisterValues: RegisterFormValueTypes = {
  username: '',
  password: '',
  email: '',
  passwordCheck: '',
  countryId: '',
};
export const RegisterForm = () => {
  const {setLoading, show, setShowModal, setMessage} = useModalContext();
  const {setMe, me} = useMeContext();
  const [register, {data}] = useRegisterMutation();
  const options = countryList().getData();

  const showMessageModal = useCallback(
    (message: string, val: boolean) => {
      setShowModal({...show, messageModal: val});
      setMessage(message);
    },
    [setMessage, setShowModal, show],
  );
  const showRegisterModal = useCallback(
    (val: boolean) => {
      setShowModal({...show, messageModal: val});
    },
    [setShowModal, show],
  );

  const registerMe = async (
    values: RegisterFormValueTypes,
    actions: FormikHelpers<RegisterFormValueTypes>,
  ) => {
    try {
      setLoading(true);
      await register({
        variables: {
          input: {
            username: values.username,
            password: values.password,
            email: values.email,
            passwordCheck: values.passwordCheck,
            countryId: values.countryId,
          },
        },
      });
      showRegisterModal(false);
      setLoading(false);
      showMessageModal('Success!', true);
    } catch (err) {
      setLoading(false);
      const {fieldError} = getErrorInfo(err);
      if (fieldError === 'email') {
        actions.setFieldError('email', `${values.email} is already in use!`);
      }
      if (fieldError === 'username') {
        actions.setFieldError(
          'username',
          `${values.username} is already in use!`,
        );
      }
    }
  };

  useEffect(() => {
    const cookie = Cookies.get('token');
    if (me || cookie) return;
    if (data && data.register && data.register.token) {
      Cookies.set('token', data.register.token);
    }
  }, [data, me, setLoading, setMe, showMessageModal, showRegisterModal]);

  const validationSchema = Yup.object({
    username: Yup.string()
      .trim()
      .min(3, 'Must be at least three characters long')
      .max(25, 'Must be 25 characters or less')
      .required('Please enter a username'),
    password: Yup.string()
      .min(
        8,
        'Please use a password with at least eight characters, including at least two numbers',
      )
      .matches(
        /.*[0-9].*[0-9].*/,
        'Please use a password with at least eight characters, including at least two numbers',
      )
      .required('Please enter a password'),
    email: Yup.string()
      .trim()
      .lowercase()
      .email('Please enter a valid email address')
      .required('Please enter an email address'),
    passwordCheck: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please enter a password'),
    countryId: Yup.string().required('Please select a country'),
  });

  return (
    <>
      <Formik
        initialValues={initialRegisterValues}
        onSubmit={(values, actions) => registerMe(values, actions)}
        validationSchema={validationSchema}
      >
        <Form autoComplete="on">
          <Box pt="ten" pb="hunTen" px="seven" maxWidth="280px">
            <TextInput
              placeholder="Enter a username..."
              name="username"
              label="Username"
              type="text"
            />
            <TextInput
              name="email"
              label="Email Address"
              type="text"
              placeholder="Enter an email address..."
            />
            <TextInput
              name="password"
              label="Password"
              type="password"
              placeholder="Enter a password..."
            />
            <TextInput
              name="passwordCheck"
              label="Re enter password"
              type="password"
              placeholder="Re-enter password"
            />
            <SelectDropdown
              name="countryId"
              label="countryId"
              type="select"
              options={options}
              defaultOptionLabel="Please select your country"
            />
            <Box my="seven">
              <Button type="submit" flex={1} variant="formButtons">
                Register!
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
      <Button onClick={() => null}>test request</Button>
    </>
  );
};

/*


  const onSubmitFormOld = async (values: RegisterFormValueTypes) => {
    const response = await tryCatchAxiosPost(
      '/register',
      values,
      (val: boolean) => setLoading(val),
      (val: boolean, message: string  TODO isError: boolean ) => {
        setShowModal({...show, messageModal: val});
        setMessage(message);
      },
      // eslint-disable-next-line no-console
      (args) => console.log('setting me will be done in future', args),
    );
    if (!response) {
      return null;
    }
    // DO SOMETHING WITH SUCCESS TODO
    // setMe()? with response
    return response;
  };

  const test = async () => {
    const response = await tryCatchAxiosPost(
      '/test',
      {hello: 5},
      (val: boolean) => setLoading(val),
      (val: boolean, message: string  , TODO isError: boolean ) => {
        setShowModal({...show, messageModal: val});
        setMessage(message);
      },
      // eslint-disable-next-line no-console
      (args) => console.log('setting me will be done in future', args),
    );

    if (!response) {
      return null;
    }
    // DO SOMETHING WITH SUCCESS TODO SETME?
    return response;
  };
*/
