/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

type PositionObject = {
  abbreviatedPosition: string;
  position: string;
};
type MessageModalContent = {
  message: string;
  show: boolean;
};
export interface ShowModal {
  driverModal: boolean;
  loginModal: boolean;
  registerModal: boolean;
  rulesModal: boolean;
  profileModal: boolean;
  raceModal: boolean;
  messageModal: boolean;
}
export interface AnimateModal {
  driverModal: boolean;
  loginModal: boolean;
  registerModal: boolean;
  rulesModal: boolean;
  profileModal: boolean;
  raceModal: boolean;
  messageModal: boolean;
}
type DriversContext = {
  show: ShowModal;
  animate: AnimateModal;
  setShowModal: Dispatch<SetStateAction<ShowModal>>;
  setAnimateModal: Dispatch<SetStateAction<AnimateModal>>;
  positionObject: PositionObject;
  setPositionObject: Dispatch<SetStateAction<PositionObject>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
};

const initialPositionObject = {
  abbreviatedPosition: '',
  position: '',
};

const modalInitialState: DriversContext = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAnimateModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
  isLoading: false,
  show: {
    driverModal: false,
    loginModal: false,
    registerModal: false,
    rulesModal: false,
    profileModal: false,
    raceModal: false,
    messageModal: false,
  },
  animate: {
    driverModal: false,
    loginModal: false,
    registerModal: false,
    rulesModal: false,
    profileModal: false,
    raceModal: false,
    messageModal: false,
  },
  positionObject: initialPositionObject,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPositionObject: () => {},
  message: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMessage: () => {},
};

const ModalContext = React.createContext(modalInitialState);

const ModalProvider = (props: {children: ReactNode}) => {
  const [show, setShowModal] = useState({...modalInitialState.show});
  const [animate, setAnimateModal] = useState({...modalInitialState.animate});
  const [positionObject, setPositionObject] = useState(initialPositionObject);
  const [isLoading, setLoading] = useState(modalInitialState.isLoading);
  const [message, setMessage] = useState('');

  return (
    <ModalContext.Provider
      value={{
        setPositionObject,
        positionObject,
        show,
        setShowModal,
        animate,
        setAnimateModal,
        isLoading,
        setLoading,
        message,
        setMessage,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};
const useModalContext = () => useContext(ModalContext);
export {useModalContext, ModalProvider};
