import React, {useState, ReactNode} from 'react';
import {SlideDown} from 'react-slidedown';
import {ModalComponent} from './modal-template/modal-component';
import {ModalHeader} from './modal-template/modal-containers';
import {useModalContext} from '../../context/modal-context';
import {theme} from '../../theme/theme';
import {SmallProfileAvatar} from '../profile-avatar';
import {Box} from '../common/box';
import {Text} from '../common/text';
import 'react-slidedown/lib/slidedown.css';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}

const ProfileSegment = (props: {title: string; children: ReactNode}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <Box display="flex" flexDirection="column" py="four" width="100%">
      <Box py="10px" px="5px" onClick={() => setShowDropdown(!showDropdown)}>
        <Text>{props.title}</Text>
      </Box>
      <Box height="1px" backgroundColor={theme.colors.lighterGrey} mr="25px" />
      <Box>
        <SlideDown className="my-dropdown-slidedown" closed={!showDropdown}>
          {props.children}
        </SlideDown>
      </Box>
    </Box>
  );
};

export const ProfileModal = (props: Props) => {
  const {show, setShowModal, animate, setAnimateModal} = useModalContext();

  const showProfileModal = (val: boolean) => {
    setShowModal({...show, profileModal: val});
  };
  const animateProfileModal = (val: boolean) => {
    setAnimateModal({...animate, profileModal: val});
  };
  // https://www.npmjs.com/package/react-slidedown
  return (
    <>
      {show.profileModal ? (
        <ModalComponent
          show={show.profileModal}
          animate={animate.profileModal}
          setAnimate={animateProfileModal}
          backgroundColor={props.backgroundColor || 'white'}
          toggle={showProfileModal}
          modalSize={{
            height: theme.sizes.normalModalHeight,
            width: theme.sizes.normalModalWidth,
          }}
        >
          {props.showHeader ? (
            <ModalHeader
              title="Profile"
              animate={animate.profileModal}
              setAnimate={animateProfileModal}
              show={show.profileModal}
            >
              <SmallProfileAvatar
                title="MussMann"
                id={1}
                description="claimin live apocalpyto style bitches watch out"
                score={204}
                countryId="US"
                imageURI="https://scontent-lhr8-1.xx.fbcdn.net/v/t1.0-9/12047176_10207190506338086_4705687229845542517_n.jpg?_nc_cat=104&_nc_sid=43edb5&_nc_oc=AQl-fsug6rRSqVCwmA5NGh7uTxK8ykBWWq0vpVuY6li1ILVd9T7tNv51btKsmwdYqUI&_nc_ht=scontent-lhr8-1.xx&oh=67f2afa39411b93b376beed024282033&oe=5EA75624"
              />
            </ModalHeader>
          ) : null}
          <Box
            width="100%"
            height="100%"
            flexDirection="column"
            display="flex"
            mt="four"
          >
            <Box mt="two" mb="six" mx="three">
              <ProfileSegment title="View League Results">
                <Text>League results tables</Text>
                <Text>League results tables</Text>
                <Text>League results tables</Text>
              </ProfileSegment>
              <ProfileSegment title="View My Score Breakdown">
                <Text>SCORE BREKADOWN</Text>
                <Text>SCORE BREKADOWN</Text>
                <Text>SCORE BREKADOWN</Text>
              </ProfileSegment>
              <ProfileSegment title="View My Stats">
                <Text>SCORE STATSSSS</Text>
                <Text>SCORE STATSSSS</Text>
                <Text>SCORE STATSSSS</Text>
              </ProfileSegment>
              <ProfileSegment title="Edit Profile">
                <Text>EDIT THE PROFILEEE</Text>
                <Text>EDIT THE PROFILEEE</Text>
                <Text>EDIT THE PROFILEEE</Text>
              </ProfileSegment>
            </Box>
          </Box>
        </ModalComponent>
      ) : null}
    </>
  );
};
