import React, {ReactNode, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Box} from '../../common/box';
import {theme} from '../../../theme/theme';
import {Button} from '../../common/button';
import {usePredictorContext} from '../../../context/predictor-context';
import {useModalContext} from '../../../context/modal-context';
import {getDriverString} from '../../../utils/get-driver-string';

const ModalContainer = styled.div<{
  modalXLocation?: number;
  modalYLocation?: number;
  animate: boolean;
  modalSize?: {
    height: string;
    width: string;
  };
  backgroundColor?: string;
}>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'white'};
  height: ${(props) =>
    props.modalSize ? props.modalSize.height : theme.sizes.normalModalHeight};
  width: ${(props) =>
    props.modalSize ? props.modalSize.width : theme.sizes.normalModalWidth};
  box-shadow: ${theme.shadows.modalShadow};
  border-radius: 15px;
  border-width: 2px;
  border-color: ${theme.border.modalBorder};
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: none;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  transition: top ease-in 0.7s;
  transform: translate(-50%, -55%);
`;

/*
TODO STILL IMPLEMENT
left: ${(props) => {
    if (!props.animate) {
      return `${props.modalYLocation}%`;
    }
    return '50%';
  }};
  top: ${(props) => {
    if (!props.animate) {
      return `${props.modalXLocation}%`;
    }
    return '50%';
  }};
  z-index: 100;
  transition: top ease-in 0.7s;
left: ${(props) => {
    if (!props.animate) {
      return `${props.modalYLocation}%`;
    }
    return '50%';
  }};
  top: ${(props) => {
    if (!props.animate) {
      return `${props.modalXLocation}%`;
    }
    return '50%';
  }};
    transform: ${(props) => {
    if (!props.animate) {
      return `translate(-50%, -50%)`;
    }
    return `translate(0%, 0%);`;
  }}
*/
interface Props {
  children: ReactNode;
  modalSize?: {
    height: string;
    width: string;
  };
}

interface BackgroundProps {
  children: ReactNode;
  animate: boolean;
}

export const ModalBackGroundColor = styled.div<{animate: boolean}>`
  background-color: ${(props) => {
    if (props.animate) {
      return `${theme.colors.modalBackground}`;
    }
    return `${theme.colors.initialModalBackground}`;
  }};
  opacity: ${(props) => {
    if (props.animate) {
      return 1;
    }
    return 0;
  }};
  min-height: ${theme.sizes.screenHeight};
  min-width: ${theme.sizes.screenWidth};
  position: fixed;
  left: 0;
  top: 0;
  zIndex= 10;
  transition: all ease-in 1s;
`;

interface HeaderProps {
  title?: string;
  driverTitle?: string;
  animate: boolean;
  setAnimate: (val: boolean) => void;
  show: boolean;
  children?: ReactNode;
}

const ModalHeader = (props: HeaderProps) => {
  const [headerString, setHeaderString] = useState('');
  const {predictorForm} = usePredictorContext();
  const {positionObject} = useModalContext();
  const {abbreviatedPosition, position} = positionObject;
  useEffect(() => {
    if (abbreviatedPosition && !props.title) {
      const driver = predictorForm[positionObject.abbreviatedPosition];
      const newButtonString = getDriverString(
        predictorForm,
        driver,
        position,
        abbreviatedPosition,
      );
      setHeaderString(newButtonString);
    }
  }, [
    abbreviatedPosition,
    predictorForm,
    positionObject.abbreviatedPosition,
    position,
    props.title,
  ]);

  return (
    <Box boxShadow="headerShadow" backgroundColor="papaya">
      {props.children ? (
        <Box p="three">{props.children}</Box>
      ) : (
        <Box m="four">{props.title || headerString}</Box>
      )}

      <Box position="absolute" top="10px" right="10px">
        <Button
          type="button"
          tabIndex={0}
          onKeyDown={() => {
            props.setAnimate(false);
          }}
          onClick={() => {
            props.setAnimate(false);
          }}
          variant="closeButton"
        >
          &#215;
        </Button>
      </Box>
    </Box>
  );
};

const ModalAnimateOpacityStyled = styled.div<{
  animate: boolean;
}>`
  opacity: ${(props) => {
    if (props.animate) {
      return 1;
    }
    return 0;
  }};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: ${(props) => {
    if (props.animate) {
      return `scale(1)`;
    }
    return `scale(0)`;
  }};
  transition: all ease-in 1s;
  height: 100%;
  width: 100%;
`;

interface ModalAnimateProps {
  children: ReactNode;
  animate: boolean;
  transitionEnd: (e: any) => void;
}

const ModalAnimateOpacity = (props: ModalAnimateProps) => {
  return (
    <Box width="100%" height="100%" position="fixed" top="0" left="0">
      <ModalAnimateOpacityStyled
        onTransitionEnd={props.transitionEnd}
        animate={props.animate}
      >
        {props.children}
      </ModalAnimateOpacityStyled>
    </Box>
  );
};

const ModalInnerContainer = (props: Props) => {
  return (
    <Box position="relative" height="100%" width="100%">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
        height={
          props.modalSize
            ? props.modalSize.height
            : `${theme.sizes.normalModalHeight}`
        }
        width={
          props.modalSize
            ? props.modalSize.width
            : `${theme.sizes.normalModalWidth}`
        }
      >
        {props.children}
      </Box>
    </Box>
  );
};

export {ModalContainer, ModalHeader, ModalAnimateOpacity, ModalInnerContainer};
