import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['Int'];
  teamName: Scalars['String'];
  countryId: Scalars['String'];
};

export type Driver = {
  __typename?: 'Driver';
  id: Scalars['Int'];
  fullname: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  countryId: Scalars['String'];
  teamId: Scalars['Int'];
  carNumber?: Maybe<Scalars['Int']>;
  team?: Maybe<Team>;
};

export type Query = {
  __typename?: 'Query';
  getDrivers: Array<Driver>;
  getMe?: Maybe<User>;
  getPredictions: Predictions;
  getRaceResults: Results;
  getRaces: Array<Race>;
  getUserScores: UserScores;
};


export type Predictions = {
  __typename?: 'Predictions';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  raceId: Scalars['Int'];
  P1: Scalars['String'];
  P2: Scalars['String'];
  P3: Scalars['String'];
  P4: Scalars['String'];
  P5: Scalars['String'];
  P6: Scalars['String'];
  P7: Scalars['String'];
  P8: Scalars['String'];
  P9: Scalars['String'];
  P10: Scalars['String'];
  poleTime: Scalars['String'];
  poleDriver: Scalars['String'];
  mPG: Scalars['String'];
  dotd: Scalars['String'];
  fastestLap: Scalars['String'];
};

export type Results = {
  __typename?: 'Results';
  id: Scalars['Int'];
  raceId: Scalars['Int'];
  P1: Scalars['String'];
  P2: Scalars['String'];
  P3: Scalars['String'];
  P4: Scalars['String'];
  P5: Scalars['String'];
  P6: Scalars['String'];
  P7: Scalars['String'];
  P8: Scalars['String'];
  P9: Scalars['String'];
  P10: Scalars['String'];
  P11: Scalars['String'];
  P12: Scalars['String'];
  P13: Scalars['String'];
  poleTime: Scalars['String'];
  poleDriver: Scalars['String'];
  mPG: Scalars['String'];
  dotd: Scalars['String'];
  fastestLap: Scalars['String'];
};

export type UserScores = {
  __typename?: 'UserScores';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  raceId: Scalars['Int'];
  P1: Scalars['Int'];
  P2: Scalars['Int'];
  P3: Scalars['Int'];
  P4: Scalars['Int'];
  P5: Scalars['Int'];
  P6: Scalars['Int'];
  P7: Scalars['Int'];
  P8: Scalars['Int'];
  P9: Scalars['Int'];
  P10: Scalars['Int'];
  pole: Scalars['Int'];
  mPG: Scalars['Int'];
  dotd: Scalars['Int'];
  fastestLap: Scalars['Int'];
};

export type Race = {
  __typename?: 'Race';
  id: Scalars['Int'];
  title: Scalars['String'];
  eventDateTime: Scalars['DateTime'];
  description: Scalars['String'];
  countryId: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Normallo = 'NORMALLO'
}

export type LoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  passwordCheck: Scalars['String'];
  countryId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  email: Scalars['String'];
  username: Scalars['String'];
  imageUri?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  role: UserRole;
  score: Scalars['Int'];
  token?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login?: Maybe<User>;
  register?: Maybe<User>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type LoginMutationVariables = {
  input: LoginInput;
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'token'>
    & MeFragmentFragment
  )> }
);

export type RegisterMutationVariables = {
  input: RegisterInput;
};


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { register?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'token'>
    & MeFragmentFragment
  )> }
);

export type MeFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'imageUri' | 'email' | 'countryId' | 'role' | 'score'>
);

export type GetMeQueryVariables = {};


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { getMe?: Maybe<(
    { __typename?: 'User' }
    & MeFragmentFragment
  )> }
);

export type GetAllRacesQueryVariables = {};


export type GetAllRacesQuery = (
  { __typename?: 'Query' }
  & { getRaces: Array<(
    { __typename?: 'Race' }
    & Pick<Race, 'id' | 'title' | 'description' | 'eventDateTime' | 'countryId'>
  )> }
);

export type GetDriversQueryVariables = {};


export type GetDriversQuery = (
  { __typename?: 'Query' }
  & { getDrivers: Array<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'fullname' | 'lastName' | 'firstName' | 'countryId' | 'teamId' | 'carNumber'>
  )> }
);

export const MeFragmentFragmentDoc = gql`
    fragment MeFragment on User {
  id
  username
  imageUri
  email
  countryId
  role
  score
}
    `;
export const LoginDocument = gql`
    mutation login($input: loginInput!) {
  login(input: $input) {
    token
    ...MeFragment
  }
}
    ${MeFragmentFragmentDoc}`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>
    } & TChildProps;
export function withLogin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps, TDataName>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: registerInput!) {
  register(input: $input) {
    token
    ...MeFragment
  }
}
    ${MeFragmentFragmentDoc}`;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export type RegisterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterMutation, RegisterMutationVariables>, 'mutation'>;

    export const RegisterComponent = (props: RegisterComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterMutation, RegisterMutationVariables> mutation={RegisterDocument} {...props} />
    );
    
export type RegisterProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>
    } & TChildProps;
export function withRegister<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisterMutation,
  RegisterMutationVariables,
  RegisterProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RegisterMutation, RegisterMutationVariables, RegisterProps<TChildProps, TDataName>>(RegisterDocument, {
      alias: 'register',
      ...operationOptions
    });
};

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const GetMeDocument = gql`
    query getMe {
  getMe {
    ...MeFragment
  }
}
    ${MeFragmentFragmentDoc}`;
export type GetMeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMeQuery, GetMeQueryVariables>, 'query'>;

    export const GetMeComponent = (props: GetMeComponentProps) => (
      <ApolloReactComponents.Query<GetMeQuery, GetMeQueryVariables> query={GetMeDocument} {...props} />
    );
    
export type GetMeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetMeQuery, GetMeQueryVariables>
    } & TChildProps;
export function withGetMe<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMeQuery,
  GetMeQueryVariables,
  GetMeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetMeQuery, GetMeQueryVariables, GetMeProps<TChildProps, TDataName>>(GetMeDocument, {
      alias: 'getMe',
      ...operationOptions
    });
};

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = ApolloReactCommon.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetAllRacesDocument = gql`
    query getAllRaces {
  getRaces {
    id
    title
    description
    eventDateTime
    countryId
  }
}
    `;
export type GetAllRacesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllRacesQuery, GetAllRacesQueryVariables>, 'query'>;

    export const GetAllRacesComponent = (props: GetAllRacesComponentProps) => (
      <ApolloReactComponents.Query<GetAllRacesQuery, GetAllRacesQueryVariables> query={GetAllRacesDocument} {...props} />
    );
    
export type GetAllRacesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetAllRacesQuery, GetAllRacesQueryVariables>
    } & TChildProps;
export function withGetAllRaces<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAllRacesQuery,
  GetAllRacesQueryVariables,
  GetAllRacesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetAllRacesQuery, GetAllRacesQueryVariables, GetAllRacesProps<TChildProps, TDataName>>(GetAllRacesDocument, {
      alias: 'getAllRaces',
      ...operationOptions
    });
};

/**
 * __useGetAllRacesQuery__
 *
 * To run a query within a React component, call `useGetAllRacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRacesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllRacesQuery, GetAllRacesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllRacesQuery, GetAllRacesQueryVariables>(GetAllRacesDocument, baseOptions);
      }
export function useGetAllRacesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllRacesQuery, GetAllRacesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllRacesQuery, GetAllRacesQueryVariables>(GetAllRacesDocument, baseOptions);
        }
export type GetAllRacesQueryHookResult = ReturnType<typeof useGetAllRacesQuery>;
export type GetAllRacesLazyQueryHookResult = ReturnType<typeof useGetAllRacesLazyQuery>;
export type GetAllRacesQueryResult = ApolloReactCommon.QueryResult<GetAllRacesQuery, GetAllRacesQueryVariables>;
export const GetDriversDocument = gql`
    query getDrivers {
  getDrivers {
    id
    fullname
    lastName
    firstName
    countryId
    teamId
    carNumber
  }
}
    `;
export type GetDriversComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDriversQuery, GetDriversQueryVariables>, 'query'>;

    export const GetDriversComponent = (props: GetDriversComponentProps) => (
      <ApolloReactComponents.Query<GetDriversQuery, GetDriversQueryVariables> query={GetDriversDocument} {...props} />
    );
    
export type GetDriversProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetDriversQuery, GetDriversQueryVariables>
    } & TChildProps;
export function withGetDrivers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDriversQuery,
  GetDriversQueryVariables,
  GetDriversProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetDriversQuery, GetDriversQueryVariables, GetDriversProps<TChildProps, TDataName>>(GetDriversDocument, {
      alias: 'getDrivers',
      ...operationOptions
    });
};

/**
 * __useGetDriversQuery__
 *
 * To run a query within a React component, call `useGetDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriversQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, baseOptions);
      }
export function useGetDriversLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, baseOptions);
        }
export type GetDriversQueryHookResult = ReturnType<typeof useGetDriversQuery>;
export type GetDriversLazyQueryHookResult = ReturnType<typeof useGetDriversLazyQuery>;
export type GetDriversQueryResult = ApolloReactCommon.QueryResult<GetDriversQuery, GetDriversQueryVariables>;