import React from 'react';
import {DriverModalFooter} from './driver-modal-footer';
import {Drivers} from './drivers';
import {ModalComponent} from '../modal-template/modal-component';
import {ModalHeader} from '../modal-template/modal-containers';
import {theme} from '../../../theme/theme';
import {useModalContext} from '../../../context/modal-context';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}
export const DriverModal = (props: Props) => {
  const {
    show,
    setShowModal,
    animate,
    setAnimateModal,
    positionObject,
  } = useModalContext();

  const showDriverModal = (val: boolean) => {
    setShowModal({...show, driverModal: val});
  };
  const animateDriverModal = (val: boolean) => {
    setAnimateModal({...animate, driverModal: val});
  };
  return (
    <>
      {show.driverModal ? (
        <ModalComponent
          animate={animate.driverModal}
          show={show.driverModal}
          setAnimate={animateDriverModal}
          backgroundColor={props.backgroundColor}
          toggle={showDriverModal}
          modalSize={{
            height: theme.sizes.normalModalHeight,
            width: theme.sizes.normalModalWidth,
          }}
        >
          {props.showHeader ? (
            <ModalHeader
              animate={animate.driverModal}
              setAnimate={animateDriverModal}
              driverTitle={positionObject.position}
              show={show.driverModal}
            />
          ) : null}
          <Drivers
            animate={animate.driverModal}
            setAnimate={animateDriverModal}
          />
          <DriverModalFooter title={positionObject.position} />
        </ModalComponent>
      ) : null}
    </>
  );
};

/*
          <DriverAvatar
            id={27}
            countryId="gb"
            title="Lewis Hamilton"
            size={AvatarSizes.VerySmall}
            type={TypeOfAvatar.Driver}
          />
          */
