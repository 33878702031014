import React from 'react';
import {GridButton} from '../reusable-components/grid-button';
import {Box} from '../reusable-components/common/box';
import {grid} from '../consts/global-vars';

export const Grid = () => {
  return (
    <Box display="flex" flexDirection="column" flex={1} pt="65vh">
      {grid.map((elem) => {
        if (
          grid.indexOf(elem) % 2 === 0 &&
          grid.indexOf(elem) < grid.length - 2 &&
          grid.indexOf(elem) === 0
        ) {
          return (
            <Box key={`${elem.title}`} display="flex" flexDirection="row">
              <Box key={`${elem.title}2`} flex={1}>
                <GridButton
                  title={elem.title}
                  variant={elem.button}
                  abbreviatedPosition={elem.abbreviated}
                  buttonType={elem.type}
                />
              </Box>
              <Box flex={1} />
            </Box>
          );
        }
        if (
          grid.indexOf(elem) % 2 === 0 &&
          grid.indexOf(elem) < grid.length - 2 &&
          grid.indexOf(elem) !== 0
        ) {
          return (
            <Box key={`${elem.title}`} display="flex" flexDirection="row">
              <Box key={`${elem.title}2`} flex={1}>
                <GridButton
                  title={elem.title}
                  variant={elem.button}
                  abbreviatedPosition={elem.abbreviated}
                  buttonType={elem.type}
                />
              </Box>
              <Box flex={1} />
            </Box>
          );
        }
        if (
          grid.indexOf(elem) % 2 === 1 &&
          grid.indexOf(elem) < grid.length - 2
        ) {
          return (
            <Box key={`${elem.title}2`} display="flex" flexDirection="row">
              <Box key={`${elem.title}3`} flex={1} />
              <Box key={`${elem.title}4`} flex={1}>
                <GridButton
                  title={elem.title}
                  variant={elem.button}
                  abbreviatedPosition={elem.abbreviated}
                  buttonType={elem.type}
                />
              </Box>
            </Box>
          );
        }
        if (grid.indexOf(elem) === grid.length - 2) {
          return (
            <Box key={`${elem.title}`} display="flex" flexDirection="column">
              <Box key={`${elem.title}2`} mt="ten" />
              <Box key={`${elem.title}3`} flex={1} mt="six">
                <GridButton
                  title={elem.title}
                  variant={elem.button}
                  abbreviatedPosition={elem.abbreviated}
                  buttonType={elem.type}
                />
              </Box>
            </Box>
          );
        }
        return (
          <Box key={`${elem.title}`} display="flex" flexDirection="column">
            <Box key={`${elem.title}2`} mt="four" />
            <Box key={`${elem.title}3`} mb="hunTwent">
              <GridButton
                title={elem.title}
                variant={elem.button}
                abbreviatedPosition={elem.abbreviated}
                buttonType={elem.type}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
