import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Box} from '../../common/box';
import {Text} from '../../common/text';
import {ReactComponent as PoleTimeTick} from '../../../assests/pole-time-tick.svg';
import {usePredictorContext} from '../../../context/predictor-context';

const StyledInput = styled.input<{inputColor?: string}>`
  color: rgba(0, 0, 0, 0.6);
  background: papayawhip;
  border: none;
  border-radius: 3px;
  width: 22px;
  display: inline;
  padding: 3px 4px;
  text-align: center;
  font-size: 12px;
  border: 0.5px solid rgba(255, 177, 10, 0.8);
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const CenterText = styled.div`
  text-align: center;
  padding: 0px 25px;
`;
export const PoleTime = (props: {
  closeModal: () => void;
  abbreviatedPosition: string;
  title: string;
}) => {
  const {predictorForm, setPredictorForm} = usePredictorContext();
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const totalSeconds = 60 * Number(minutes) + Number(seconds);

  const setPoleTime = () => {
    predictorForm.PoleTime = totalSeconds.toString();
    // predictorForm[positionObject.abbreviatedPosition] = driver;
    setPredictorForm(predictorForm);
    props.closeModal();
  };

  useEffect(() => {
    if (totalSeconds > 0) {
      const copy = {...predictorForm};
      copy.PoleTime = totalSeconds.toString();
      setPredictorForm(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, minutes]);

  useEffect(() => {
    const defaultSeconds = predictorForm.PoleTime
      ? Number(predictorForm.PoleTime) % 60
      : '';
    const defaultMinutes =
      predictorForm.PoleTime && defaultSeconds
        ? (Number(predictorForm.PoleTime) - defaultSeconds) / 60
        : '';
    if (defaultSeconds && defaultMinutes) {
      if (defaultSeconds < 10) {
        setSeconds(`0${defaultSeconds.toString()}`);
      } else {
        setSeconds(defaultSeconds.toString());
      }
      if (defaultMinutes < 10) {
        setMinutes(`0${defaultMinutes.toString()}`);
      } else {
        setMinutes(defaultMinutes.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mb={10}>
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        width="100%"
        justifyContent="center"
      >
        <Box>
          <StyledInput
            type="number"
            value={minutes}
            placeholder="MM"
            onChange={(time) => {
              setMinutes(time.target.value);
              setShowMessage(false);
            }}
          />
        </Box>
        <Box
          mx={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text fontSize="12px"> : </Text>
        </Box>
        <Box>
          <StyledInput
            type="number"
            value={seconds}
            placeholder="SS"
            onChange={(time) => {
              setSeconds(time.target.value);
              setShowMessage(false);
            }}
          />
        </Box>
      </Box>
      <Box pt={8} display="flex" flexDirection="column" justifyContent="center">
        <Box m="auto">
          <Box
            onClick={() =>
              totalSeconds > 0 && Number(seconds) < 60
                ? setPoleTime()
                : setShowMessage(true)
            }
          >
            <PoleTimeTick height="17px" width="17px" />
          </Box>
        </Box>
        <Box>
          {showMessage ? (
            <CenterText>
              <Text
                fontWeight={300}
                color="error"
                lineHeight="19px"
                textStyle="small"
              >
                Please enter a time for pole position in the format mm:ss
              </Text>
            </CenterText>
          ) : (
            <Box height="19px" />
          )}
        </Box>
      </Box>
    </Box>
  );
};
