/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import styled from 'styled-components';
import Countdown, {CountdownTimeDelta} from 'react-countdown-now';
// import Flag from 'react-world-flags';
// import Flag from 'react-flags';
// import ReactCountryFlag from 'react-country-flag';
import FlagIconFactory from 'react-flag-icon-css';
import {theme, AvatarSizes} from '../theme/theme';
import {Box} from './common/box';
import {Text} from './common/text';
import {ReactComponent as ProfileIcon} from '../assests/profile-icon.svg';
import {ReactComponent as ChequredFlagIcon} from '../assests/flag-icon.svg';
import {SvgContainer} from '../components/navigation';

const FlagIcon = FlagIconFactory(React, {useCssModules: false});

export enum TypeOfAvatar {
  Race = 'Race',
  UserProfile = 'User',
  Driver = 'Driver',
}

type RaceAvatarProps = {
  raceDeadline?: Date;
};

interface DriverProps {
  id: number;
  name: string;
  countryId: string;
  carNumber?: number | null;
  onClick: () => void;
}

type UserAvatarProps = {
  imageURI?: string;
  score?: number;
};

type BaseProps = {
  type: TypeOfAvatar;
  size: number;
  title: string;
  id: number;
  description?: string;
  countryId?: string;
  hideBackgroundColor?: boolean;
};

export type AvatarProps = BaseProps & UserAvatarProps & RaceAvatarProps;

const StyledCircularImage = styled.img<{size: number}>`
  width: auto;
  height: 100%;
  margin-left: ${(props) => {
    return -props.size / 4;
  }}px;
`;
const CircularDiv = styled.div<{
  large?: boolean;
  size: number;
}>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${(props) => (props.color ? props.color : 'default')};
  text-align: center;
`;
const StyledBackdrop = styled.div<{size: number; hideBackground?: boolean}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative;
  padding-right: 3px;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${(props) =>
    props.hideBackground ? 'none' : `${theme.colors.greyer}`};
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
`;
const BorderBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  padding-right: 1px;
`;
const CircularImageContainer = (props: {
  src: string;
  size: number;
  color?: string;
  setImageError: () => void;
}) => {
  return (
    <StyledBackdrop size={props.size}>
      <CircularDiv size={props.size} color={props.color}>
        <StyledCircularImage
          size={props.size}
          src={props.src}
          onError={() => props.setImageError()}
        />
      </CircularDiv>
    </StyledBackdrop>
  );
};
const DefaultImage = (props: {
  size: number;
  type: TypeOfAvatar;
  hideBackgroundColor?: boolean;
  countryId?: string;
}) => {
  return (
    <CircularDiv
      size={props.size}
      color={
        props.hideBackgroundColor
          ? theme.colors.darkGrey
          : theme.colors.darkGrey // TODO  ANOTHER  COLOUR HANDLE theme.colors.secondary
      }
    >
      <Box height="100%" position="relative">
        {props.type === 'Race' ? (
          <SvgContainer>
            <ChequredFlagIcon
              fill={theme.colors.grey}
              height="100%"
              width="100%"
            />
          </SvgContainer>
        ) : props.type === 'User' ? (
          <SvgContainer>
            <ProfileIcon height="100%" width="100%" fill={theme.colors.grey} />
          </SvgContainer>
        ) : props.countryId ? (
          <BorderBox>
            <FlagIcon
              squared
              styleName="driver-flags"
              code={props.countryId.toLowerCase()}
            />
          </BorderBox>
        ) : null}
      </Box>
    </CircularDiv>
  );
};

interface AvatarBackdropProps {
  imageURI?: string;
  size: number;
  isLarge?: boolean;
  // isRace?: boolean;
  type: TypeOfAvatar;
  showPapayaBackground?: boolean;
  countryId?: string;
}
const AvatarBackdrop = (props: AvatarBackdropProps) => {
  const [isImageError, setImageError] = useState(false);

  const DefaultImageComponent = (childProps: AvatarBackdropProps) => {
    return (
      <Box minWidth={`${childProps.size}px`}>
        <DefaultImage
          type={childProps.type}
          hideBackgroundColor={childProps.type === 'Race'}
          size={childProps.size}
          countryId={childProps.countryId}
        />
      </Box>
    );
  };
  if (isImageError) {
    return (
      <DefaultImageComponent
        countryId={props.countryId}
        type={props.type}
        size={props.size}
      />
    );
  }
  return (
    <>
      {props.imageURI ? (
        <CircularImageContainer
          setImageError={() => setImageError(true)}
          size={props.size}
          src={props.imageURI}
        />
      ) : (
        <DefaultImageComponent
          countryId={props.countryId}
          type={props.type}
          size={props.size}
        />
      )}
    </>
  );
};

const Timer = (props: {date: Date; description?: string}) => {
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownTimeDelta) => {
    const daysUntilRace = days + 1;
    if (completed) {
      // SEAMUS TODO WITH RACE MODAL
      // call unavailable func
      return <div>hello</div>;
    }
    if (daysUntilRace > 7) {
      return (
        <Text
          fontWeight={300}
          textStyle="smallMedium"
        >{`${daysUntilRace} Days  Until the ${props.description}!`}</Text>
      );
    }
    if (daysUntilRace === 7) {
      return (
        <Text
          fontWeight={300}
          textStyle="smallMedium"
        >{`${daysUntilRace} Week Until the ${props.description}!`}</Text>
      );
    }
    if (daysUntilRace < 7 && daysUntilRace > 1) {
      return (
        <Text
          fontWeight={300}
          textStyle="smallMedium"
        >{`${daysUntilRace} Days  Until the ${props.description}!`}</Text>
      );
    }
    if (daysUntilRace === 1) {
      return (
        <Text
          fontWeight={300}
          textStyle="smallMedium"
        >{`Deadline in ${hours} Hours ${minutes}:${seconds}`}</Text>
      );
    }
    return (
      <Text
        fontWeight={300}
        textStyle="smallMedium"
      >{`${daysUntilRace} Days,  ${hours}:${minutes}:${seconds}`}</Text>
    );
  };
  return (
    <Box>
      <Countdown date={props.date} renderer={renderer} />
    </Box>
  );
};

const Avatar = (props: AvatarProps) => {
  const [background, setBackground] = useState('grey');

  const largeAvatarBreakPoint = AvatarSizes.LargeAvatar;
  const mediumAvatarBreakpoint = AvatarSizes.MediumAvatar;
  const smallAvatarBreakpoint = AvatarSizes.SmallAvatar;
  const isLarge = props.size > largeAvatarBreakPoint;
  const isMedium =
    props.size <= largeAvatarBreakPoint && props.size > mediumAvatarBreakpoint;
  const isSmall = props.size <= smallAvatarBreakpoint;
  return (
    <Box
      display="flex"
      flexDirection="row"
      backgroundColor={props.hideBackgroundColor ? 'transparent' : background}
      borderRadius={`${props.size / 2}px`}
      border={
        props.hideBackgroundColor
          ? 'none'
          : `0.5px solid ${theme.colors.darkGrey}`
      }
      height={`${props.size}px`}
      onMouseDown={() => setBackground('greyer')}
      onMouseUp={() => setBackground('grey')}
      onTouchStart={() => setBackground('greyer')}
      onTouchEnd={() => setBackground('grey')}
    >
      <AvatarBackdrop
        imageURI={props.imageURI}
        size={props.size}
        isLarge={isLarge}
        type={props.type}
        countryId={props.countryId}
        // isRace={props.type === TypeOfAvatar.Race}
      />
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        ml={isLarge ? 'three' : 'two'}
        mr={props.type === 'Driver' ? 'four' : isLarge ? 'three' : 'two'}
        justifyContent="center"
      >
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="row">
            {props.countryId && props.type !== 'Driver' ? (
              <Box
                mr={isSmall ? 5 : 3}
                width="auto"
                maxHeight="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box width="100%" height="auto">
                  <FlagIcon code={props.countryId.toLowerCase()} />
                </Box>
              </Box>
            ) : null}
            <Box
              display="flex"
              height="100%"
              flexDirection="column"
              justifyContent="center"
            >
              <Text
                color="black"
                textStyle={
                  props.type === 'Driver'
                    ? 'smallMedium'
                    : isLarge
                    ? 'extraLarge'
                    : 'smallMedium'
                }
                fontWeight={props.type === 'Driver' ? 300 : isLarge ? 500 : 400}
              >
                {props.title}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box my={isLarge || isMedium ? 3 : 1} />
        {props.type === TypeOfAvatar.UserProfile ? (
          <Text
            color={isLarge ? 'veryDarkGrey' : 'black'}
            textStyle={isLarge ? 'large' : 'small'}
            fontWeight={isLarge || props.hideBackgroundColor ? 300 : 200}
          >
            {props.description}
          </Text>
        ) : props.type === TypeOfAvatar.Race && props.raceDeadline ? (
          <Timer date={props.raceDeadline} description={props.description} />
        ) : null}
        {isLarge &&
        props.score &&
        props.type === TypeOfAvatar.UserProfile &&
        false ? (
          <Box>
            <Text
              color="veryDarkGrey"
              ml="two"
              mt="one"
              textStyle="smallMedium"
              fontWeight={400}
            >
              #{props.score}
            </Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
type GeneralBaseProps = {
  size?: number;
  title: string;
  id: number;
  description?: string | undefined;
  countryId?: string | undefined;
  hideBackgroundColor?: boolean | undefined;
};
// not currently  in use
export const ProfileAvatar = (props: GeneralBaseProps & UserAvatarProps) => {
  return (
    <Avatar
      type={TypeOfAvatar.UserProfile}
      size={AvatarSizes.SmallAvatar}
      {...props}
    />
  );
};

export const SmallProfileAvatar = (
  props: GeneralBaseProps & UserAvatarProps,
) => {
  return (
    <Avatar
      type={TypeOfAvatar.UserProfile}
      size={AvatarSizes.SmallAvatar}
      hideBackgroundColor
      {...props}
    />
  );
};

export const RaceAvatar = (props: GeneralBaseProps & RaceAvatarProps) => {
  return (
    <Avatar
      type={TypeOfAvatar.Race}
      size={AvatarSizes.MediumAvatar}
      {...props}
    />
  );
};

export const DriversAvatar = (props: DriverProps) => {
  return (
    <Box onClick={() => props.onClick()}>
      <Avatar
        title={props.name}
        type={TypeOfAvatar.Driver}
        size={AvatarSizes.SmallAvatar}
        {...props}
      />
    </Box>
  );
};

export const DriverAvataroold = (props: DriverProps) => {
  const size = 25;
  return (
    <Box
      onClick={props.onClick}
      minWidth="130px"
      maxWidth="160px"
      minHeight="25px"
      maxHeight="30px"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="row"
        pr={7}
        // justifyContent="center"
        height="100%"
        flex={1}
        border="1px solid rgba(0, 0, 0, 0.28)"
        borderRadius="15px"
        backgroundColor="inputs"
      >
        <Box
          display="flex"
          flexDirection="column"
          // px={2}
          justifyContent="center"
        >
          <StyledBackdrop hideBackground size={size}>
            <CircularDiv size={size} color="none">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                flex={1}
                height="100%"
                width="100%"
                pr={1}
              >
                <FlagIcon
                  squared
                  styleName="driver-flags"
                  code={props.countryId.toLowerCase()}
                />
              </Box>
            </CircularDiv>
          </StyledBackdrop>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Text color="black" textStyle="smallMedium" fontWeight={300}>
            {props.name}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
