import React, {useEffect, ReactNode} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  ModalAnimateOpacity,
  ModalContainer,
  ModalInnerContainer,
  ModalBackGroundColor,
} from './modal-containers';
import {Box} from '../../common/box';
import {useModalContext} from '../../../context/modal-context';

interface Props {
  children: ReactNode;
  modalSize?: {
    height: string;
    width: string;
  };
  backgroundColor?: string;
  animate: boolean;
  toggle: (val: boolean, message?: string) => void;
  setAnimate: (val: boolean) => void;
  show: boolean;
}

export const ModalComponent = (props: Props) => {
  const {show} = useModalContext();
  const transitionEnd = (e: any) => {
    if (e.propertyName !== 'opacity' || props.animate) {
      return;
    }
    if (show) {
      props.toggle(false);
    }
  };

  useEffect(() => {
    props.setAnimate(true);
    return () => {
      if (props.animate) {
        props.setAnimate(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%" height="100%" position="fixed" top="0" left="0">
      <ModalBackGroundColor animate={props.animate}>
        <ModalAnimateOpacity
          animate={props.animate}
          transitionEnd={transitionEnd}
        >
          <OutsideClickHandler onOutsideClick={() => props.setAnimate(false)}>
            <ModalContainer
              animate={props.animate}
              modalSize={props.modalSize ? props.modalSize : undefined}
              backgroundColor={props.backgroundColor}
            >
              <ModalInnerContainer
                modalSize={props.modalSize ? props.modalSize : undefined}
              >
                {props.children}
              </ModalInnerContainer>
            </ModalContainer>
          </OutsideClickHandler>
        </ModalAnimateOpacity>
      </ModalBackGroundColor>
    </Box>
  );
};
