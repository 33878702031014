import React from 'react';
import {Box} from './box';
import {Text} from './text';

interface Props {
  error: string | null;
  center?: boolean;
}
export const ErrorMessageComponent = (props: Props) => {
  return (
    <Box ml="two" my="two" height="three">
      {props.error ? (
        <Text
          fontWeight={300}
          color="error"
          lineHeight="19px"
          textStyle="verySmall"
        >
          {props.error}
        </Text>
      ) : (
        <Box>&nbsp;</Box>
      )}
    </Box>
  );
};
