import React from 'react';
import styled from 'styled-components';
import {Box} from '../reusable-components/common/box';
import {ReactComponent as RacecarIcon} from '../assests/car-icon.svg';
import {theme} from '../theme/theme';

const StledContainer = styled.div`
  padding-top: 30vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const LoadingWheel = () => {
  return (
    <>
      <StledContainer className="lds-ring">
        <Box />
        <Box />
        <Box />
        <Box />
      </StledContainer>
      <StledContainer>
        <RacecarIcon width="45px" fill={theme.colors.papaya} />
      </StledContainer>
    </>
  );
};
