import ApolloClient, {InMemoryCache, ApolloLink, HttpLink} from 'apollo-boost';
import Cookies from 'js-cookie';
import {endPoint} from '../consts/global-vars';

const client = new ApolloClient({
  uri: endPoint,
  request: async (operation) => {
    const token = Cookies.get('token');
    console.log('token in operation', token);
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    console.log('new operation', operation);
  },
  cache: new InMemoryCache(),
  /*
    TODO HANDLE ONERRORS Show message modal maybe?
    onError: (error) => {
      const errorCode = getCustomErrorCodeFromError(error);
      if (errorCode === CustomErrorCodes.NOT_SUBSCRIBED) {
        setUserNotSubscribed(true);
      }
    },
  */
});

export {client};
