import React, {useEffect, useState} from 'react';
import {Swipeable} from 'react-swipeable';
import {Box} from './common/box';
import {Button} from './common/button';
import {useModalContext} from '../context/modal-context';
import {usePredictorContext} from '../context/predictor-context';
import {getDriverString} from '../utils/get-driver-string';

interface Props {
  variant: string;
  title: string;
  abbreviatedPosition: string;
  buttonType: string;
}

export const GridButton = (props: Props) => {
  const [buttonString, setButtonString] = useState('');
  const {
    setPositionObject,
    positionObject,
    show,
    setShowModal,
  } = useModalContext();

  const {predictorForm, setPredictorForm} = usePredictorContext();
  const setPosition = () => {
    const copy = {...positionObject};
    copy.position = props.title;
    copy.abbreviatedPosition = props.abbreviatedPosition;
    // positionObject.position = props.title;
    setPositionObject(copy);
  };
  const driver = predictorForm[props.abbreviatedPosition]
    ? predictorForm[props.abbreviatedPosition].split(' ')[1]
    : predictorForm[props.abbreviatedPosition];

  const clearSelection = () => {
    const newForm = {...predictorForm};
    newForm[positionObject.abbreviatedPosition] = '';
    setPredictorForm(newForm);
  };

  useEffect(() => {
    const newButtonString = getDriverString(
      predictorForm,
      driver,
      props.title,
      props.abbreviatedPosition,
    );
    setButtonString(newButtonString);
  }, [
    driver,
    predictorForm,
    predictorForm.PoleTime,
    props.abbreviatedPosition,
    props.title,
  ]);
  return (
    <Box
      mx="two"
      my="three"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Box flex={1}>
        <Swipeable
          preventDefaultTouchmoveEvent
          onSwiped={(swipeEvent) => {
            return (props.buttonType === 'event' ||
              props.buttonType === 'grid') &&
              swipeEvent.absX > 50 &&
              predictorForm[props.abbreviatedPosition].length > 0
              ? clearSelection()
              : null;
          }}
        >
          <Button
            variant={driver ? 'predictorButtonClicked' : props.variant}
            onClick={() => {
              if (props.buttonType === 'race') {
                setShowModal({...show, raceModal: true});
              } else if (props.buttonType === 'submit') {
                // eslint-disable-next-line no-console
                console.log('ij');
              } else {
                setShowModal({...show, driverModal: true});
                setPosition();
              }
            }}
          >
            {buttonString}
          </Button>
        </Swipeable>
      </Box>
    </Box>
  );
};
