import React from 'react';
import {ModalComponent} from './modal-template/modal-component';
import {ModalHeader} from './modal-template/modal-containers';
import {useModalContext} from '../../context/modal-context';
import {theme} from '../../theme/theme';
import {RegisterForm} from '../../forms/register';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}

export const RegisterModal = (props: Props) => {
  const {show, setShowModal, animate, setAnimateModal} = useModalContext();
  const showRegisterModal = (val: boolean) => {
    setShowModal({...show, registerModal: val});
  };
  const animateRegisterModal = (val: boolean) => {
    setAnimateModal({...animate, registerModal: val});
  };

  return (
    <>
      {show.registerModal ? (
        <ModalComponent
          show={show.registerModal}
          animate={animate.registerModal}
          setAnimate={animateRegisterModal}
          backgroundColor={props.backgroundColor}
          toggle={showRegisterModal}
          modalSize={{
            height: theme.sizes.tinyModalHeight,
            width: theme.sizes.normalModalWidth,
          }}
        >
          {props.showHeader ? (
            <ModalHeader
              title="Register"
              animate={animate.registerModal}
              setAnimate={animateRegisterModal}
              show={show.registerModal}
            />
          ) : null}
          <RegisterForm />
        </ModalComponent>
      ) : null}
    </>
  );
};
