import React from 'react';
import {LoginForm} from '../../forms/login';
import {ModalComponent} from './modal-template/modal-component';
import {ModalHeader} from './modal-template/modal-containers';
import {useModalContext} from '../../context/modal-context';
import {theme} from '../../theme/theme';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}

export const LoginModal = (props: Props) => {
  const {show, setShowModal, animate, setAnimateModal} = useModalContext();
  const showLoginModal = (val: boolean) => {
    setShowModal({...show, loginModal: val});
  };
  const animateLoginModal = (val: boolean) => {
    setAnimateModal({...animate, loginModal: val});
  };

  return (
    <>
      {show.loginModal ? (
        <ModalComponent
          show={show.loginModal}
          animate={animate.loginModal}
          setAnimate={animateLoginModal}
          backgroundColor={props.backgroundColor}
          toggle={showLoginModal}
          modalSize={{
            height: theme.sizes.tinyModalHeight,
            width: theme.sizes.tinyModalWidth,
          }}
        >
          {props.showHeader ? (
            <ModalHeader
              title="Login"
              animate={animate.loginModal}
              setAnimate={animateLoginModal}
              show={show.loginModal}
            />
          ) : null}
          <LoginForm />
        </ModalComponent>
      ) : null}
    </>
  );
};
