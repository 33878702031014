import React, {useEffect, useCallback} from 'react';
import * as Yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import Cookies from 'js-cookie';
import {Box} from '../reusable-components/common/box';
import {TextInput} from '../reusable-components/common/text-input';
import {Button} from '../reusable-components/common/button';
import {useModalContext} from '../context/modal-context';
import {useLoginMutation} from '../graphql/generated';
import {useMeContext} from '../context/user';
import {getErrorInfo} from '../utils/error-handlers';

export interface LoginFormTypes {
  username: string;
  password: string;
}

const initialFormValues: LoginFormTypes = {
  username: '',
  password: '',
};

export const LoginForm = () => {
  const {
    setLoading,
    show,
    setShowModal,
    setMessage,
    setAnimateModal,
  } = useModalContext();
  const {setMe} = useMeContext();
  const [loginMutation, {data}] = useLoginMutation();

  const validationSchema = Yup.object({
    username: Yup.string()
      .trim()
      .min(3, 'Must be at least three characters long')
      .max(25, 'Must be 25 characters or less')
      .required('Please enter a username'),
    password: Yup.string()
      .min(8, 'Must be at least eight characters long')
      .required('Please enter a password'),
  });

  const showMessageModal = useCallback(
    (message: string, val: boolean) => {
      setShowModal({...show, messageModal: val});
      setMessage(message);
    },
    [setMessage, setShowModal, show],
  );
  const showRegisterModal = useCallback(
    (val: boolean) => {
      setShowModal({...show, registerModal: val});
    },
    [setShowModal, show],
  );

  const showLoginModal = useCallback(
    (val: boolean) => {
      setAnimateModal({...show, loginModal: val});
    },
    [setAnimateModal, show],
  );
  const logMeIn = async (
    username: string,
    password: string,
    action: FormikHelpers<LoginFormTypes>,
  ) => {
    try {
      setLoading(true);
      await loginMutation({
        variables: {
          input: {
            username,
            password,
          },
        },
      });
    } catch (err) {
      setLoading(false);
      const {fieldError} = getErrorInfo(err);
      if (fieldError === 'password') {
        action.setFieldError('password', 'incorrect password');
        return;
      }
      if (fieldError === 'username') {
        action.setFieldError('username', `${username} doesn't exist!`);
      }
    }
  };

  useEffect(() => {
    //
    if (data && data.login && data.login.token) {
      Cookies.set('token', data.login.token);
      setMe(data.login);
    }
  }, [data, setMe]);

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        onSubmit={(
          values: LoginFormTypes,
          actions: FormikHelpers<LoginFormTypes>,
        ) => logMeIn(values.username, values.password, actions)}
        validationSchema={validationSchema}
      >
        <Form autoComplete="on">
          <Box
            position="absolute"
            top={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Box py="ten" pl="four" pr="eight" maxWidth="280px">
              <TextInput
                placeholder="Enter your username..."
                name="username"
                label="Username"
                type="text"
              />
              <TextInput
                name="password"
                label="Password"
                type="password"
                placeholder="Enter your password..."
              />

              <Button
                name="submit"
                type="submit"
                flex={1}
                variant="formButtons"
              >
                Login
              </Button>
              <Button
                onClick={() => {
                  showLoginModal(false);
                  showRegisterModal(true);
                }}
                flex={1}
                variant="formButtons"
                type="button"
              >
                Register
              </Button>
            </Box>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
/*
  const onSubmitFormOLD = async (
    data: LoginFormTypes,
    formActions: FormikHelpers<LoginFormTypes>,
  ) => {
    try {
      setLoading(true);
      login({
        variables: {
          // username: data.username,
          password: data.password,
        },
      });
      setLoading(false);
      return true;
    } catch (e) {
      setLoading(false);
      if (e.response && e.response.data && e.response.data.showMessage) {
        setMessage(e.response.data.message);
        setShowModal({...show, messageModal: true});
      }
      if (e.response && e.response.data && e.response.data.formError) {
        formActions.setFieldError('password', 'Incorrect Password!');
      }
      return null;
    }
  };

*/
