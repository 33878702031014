import {CSSObject} from 'styled-components';

interface TextStyle extends CSSObject {
  fontSize: number;
}
interface ExtraButtonTypes {
  [variantName: string]: string;
}
interface ButtonInterface {
  [variantName: string]: string | number | ExtraButtonTypes;
}
export enum AvatarSizes {
  SmallAvatar = 30,
  MediumAvatar = 50,
  LargeAvatar = 80,
  XLargeAvatar = 100,
}
export interface ThemeTypes {
  colors: {
    [variantName: string]: string;
  };
  space: {
    [variantName: string]: number | string;
  };
  textStyles: {
    [variantName: string]: TextStyle;
  };
  border: {
    [variantName: string]: string;
  };
  fontFamily: string;
  fontWeight: {
    [variantName: string]: number;
  };
  buttons: {
    [variantName: string]: ButtonInterface;
  };
  sizes: {
    [variantName: string]: string;
  };
  zIndices: {
    [variantName: string]: number;
  };
  shadows: {
    [variantName: string]: string;
  };
  radii: {
    [variantName: string]: string;
  };
}
const fontFamily = "'Roboto', sans-serif";
const space = {
  one: 0,
  two: 5,
  three: 10,
  four: 15,
  five: 25,
  six: 30,
  seven: 35,
  eight: 40,
  nine: 50,
  ten: 60,
  eleven: 70,
  twelve: 80,
  thirteenth: 90,
  hun: 100,
  hunTen: 110,
  hunTwent: 120,
  hunFort: 140,
  hunSixt: 160,
  twoHun: 200,
  smallAvatar: AvatarSizes.SmallAvatar,
  mediumAvatar: AvatarSizes.MediumAvatar,
  largeAvatar: AvatarSizes.LargeAvatar,
};
const sizes = {
  screenHeight: '100vh',
  screenWidth: '100vw',
  tinyModalWidth: '65vw',
  tinyModalHeight: '45vh',
  normalModalWidth: '85vw',
  normalModalHeight: '72vh',
  largeModalWidth: '85vw',
  largeModalHeight: '85vh',
};
const colors = {
  white: '#fff',
  black: '#000000',
  error: '#cc0000',
  pale: '#fefefe',
  grey: '#e5e5e5',
  greyer: '#cdcdcd',
  darkGrey: '#bababa',
  secondary: '#0a58ff',
  darkerGrey: '#999999',
  veryDarkGrey: '#575757',
  papaya: 'rgb(255, 177, 10)',
  opaqueLightPapaya: 'rgba(255, 177, 10,0.8)',
  opaqueLighterPapaya: 'rgba(255, 177, 10, 0.65)',
  lightGrey: 'rgba(0,0,0,0.8)',
  lighterGrey: 'rgba(0,0,0,0.4)',
  modalBackground: 'rgba(0, 0, 0, 0.25)',
  initialModalBackground: 'rgba(0, 0, 0, 0)',
  hoverInputs: 'rgba(0,0,0,0.18)',
  inputs: 'rgba(0, 0, 0, 0.08)',
  button: 'rgba(0,0,0,0.67)',
  buttonActive: 'rgba(255, 177, 10, 0.57)',
  modalBorder: 'rgb(255, 177, 10)',
  opaque: 'rgba(0,0,0,0)',
  navMenu: 'rgb(246, 135, 40)',
  errorTint: '#FFEEF1',
};
const fontWeight = {
  light: 200,
  medium: 300,
  heavier: 500,
};
const textStyles = {
  verySmall: {
    fontSize: 10,
  },
  small: {
    fontSize: 12,
  },
  smallMedium: {
    fontSize: 14,
  },
  medium: {
    fontSize: 15,
  },
  large: {
    fontSize: 18,
  },
  extraLarge: {
    fontSize: 21,
  },
  extraExtraLarge: {
    fontSize: 24,
  },
};
const border = {
  button: '1.2px solid black',
  bigButton: '1.35px solid black',
  driverButton: '1px solid rgba(0, 0, 0, 0.28)',
  driverButtonHover: '1px solid rgba(0, 0, 0, 0.48)',
  clearButton: '0.5px solid rgba(0, 0, 0, 0.38)',
  clearButtonHover: '0.5px solid rgba(0, 0, 0, 0.48)',
};
const radii = {
  button: '12px',
  modal: '15px',
};
const zIndices = {
  modalBackground: 50,
  modal: 100,
};
const shadows = {
  buttonShadow: '0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.1)',
  modalShadow: '0 4px 8px 0 rgba(0,0,0,0.19),0 6px 20px 0 rgba(0,0,0,0.19)',
  headerShadow: '0 2px 4px 0 rgba(0,0,0,0.19),0 3px 8px 0 rgba(0,0,0,0.19)',
};
const buttons = {
  predictorButton: {
    backgroundColor: colors.button,
    color: colors.white,
    textShadow: 'none',
    position: 'relative',
    bottom: '0px',
    transition: 'background 0.3s, text-shadow 0.5s, bottom 0.1s',
    border: border.button,
    borderRadius: '13px',
    minHeight: `${space.five}px`,
    maxHeight: `${space.ten}px`,
    minWidth: `${space.eleven}px`,
    maxWidth: `${space.hunFort}px`,
    fontWeight: 300,
    fontSize: '12px',
    padding: '4px 8px',
    '&:hover, :active': {
      backgroundColor: colors.buttonActive,
      textShadow: '1px 2px 2px black',
      bottom: '2px',
    },
  },
  predictorButtonClicked: {
    backgroundColor: colors.buttonActive,
    color: colors.white,
    textShadow: 'none',
    position: 'relative',
    bottom: '0px',
    transition: 'background 0.3s, text-shadow 0.5s, bottom 0.1s',
    border: border.button,
    borderRadius: '13px',
    minHeight: `${space.five}px`,
    maxHeight: `${space.ten}px`,
    minWidth: `${space.eleven}px`,
    maxWidth: `${space.hunFort}px`,
    fontWeight: 300,
    fontSize: '12px',
    padding: '4px 8px',
    '&:hover, :active': {
      textShadow: '1px 2px 2px black',
      bottom: '2px',
    },
  },
  largerPredictorButton: {
    backgroundColor: colors.button,
    color: colors.white,
    minHeight: space.six,
    maxHeight: space.nine,
    width: space.twoHun,
    border: border.bigButton,
    borderRadius: '14px',
    fontWeight: '300',
    fontSize: '12.5px',
    padding: '2px 15px',
    '&:hover, :active': {
      backgroundColor: 'rgba(255, 177, 10, 0.68)',
      textShadow: '1px 3px 2px black',
      transition: 'background .2s, text-shadow 0.3s',
    },
  },
  driverButton: {
    fontSize: '12px',
    borderRadius: '12px',
    backgroundColor: colors.inputs,
    minHeight: space.five,
    maxHeight: space.nine,
    minWidth: space.hun,
    maxWidth: space.hunTwent,
    padding: '2px 8px',
    position: 'relative',
    margin: '0 auto',
    bottom: '0px',
    border: border.driverButton,
    '&:hover, :active': {
      backgroundColor: colors.hoverInputs,
      border: border.driverButtonHover,
      transition: 'background 0.2s, border 0.3s',
      bottom: '2px',
    },
  },
  clearButton: {
    fontSize: '9px',
    borderRadius: '15px',
    backgroundColor: colors.inputs,
    minHeight: space.four,
    maxHeight: space.six,
    minWidth: space.nine,
    maxWidth: space.hun,
    display: 'block',
    padding: '1px 5px',
    margin: '0 auto',
    border: border.clearButton,
    '&:hover, :active': {
      backgroundColor: colors.hoverInputs,
      border: border.clearButtonHover,
      transition: 'background 0.1s, border 0.2s',
      bottom: '3px',
    },
  },
  formButtons: {
    fontSize: '14px',
    borderRadius: '15px',
    backgroundColor: colors.inputs,
    minHeight: space.four,
    maxHeight: space.six,
    width: space.thirteenth,
    padding: '2px 8px',
    position: 'relative',
    margin: '0 3px',
    bottom: '0px',
    border: border.driverButton,
    '&:hover, :active': {
      backgroundColor: colors.hoverInputs,
      border: border.driverButtonHover,
      transition: 'background 0.2s, border 0.3s',
      bottom: '2px',
    },
  },
  closeButton: {
    fontSize: '20px',
    border: 'none',
    backgroundColor: colors.opaque,
    position: 'relative',
    bottom: '0px',
    '&:hover, :active': {
      transition: 'all 0.3s',
      bottom: '2px',
    },
  },
};
export const theme: ThemeTypes = {
  colors,
  space,
  textStyles,
  border,
  fontFamily,
  fontWeight,
  buttons,
  sizes,
  zIndices,
  shadows,
  radii,
};
