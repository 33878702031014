import {ApolloError} from 'apollo-boost';

interface ErrorInfo {
  fieldError?: string;
}
export const getErrorInfo: (err: ApolloError) => ErrorInfo = (
  err: ApolloError,
) => {
  if (err.graphQLErrors[0].extensions) {
    return err.graphQLErrors[0].extensions.extraInfo;
  }
  return {};
};

export const getErrorMessage: (err: ApolloError) => string = (
  err: ApolloError,
) => {
  const thing = err.graphQLErrors[0].message as string;
  return thing;
};
