import React, {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import {form, SelectionValues} from '../consts/global-vars';

type PredictorControllerContext = {
  setPredictorForm: Dispatch<SetStateAction<SelectionValues>>;
  predictorForm: SelectionValues;
};

const initialContextObject: PredictorControllerContext = {
  predictorForm: form,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPredictorForm: () => {},
};

const PredictorContext = React.createContext(initialContextObject);

const PredictorProvider = (props: {children: ReactNode}) => {
  const [predictorForm, setPredictorForm] = useState(form);
  return (
    <PredictorContext.Provider value={{predictorForm, setPredictorForm}}>
      {props.children}
    </PredictorContext.Provider>
  );
};
const usePredictorContext = () => useContext(PredictorContext);
export {usePredictorContext, PredictorProvider};
