import React, {useRef, useEffect, useState} from 'react';
import {Box} from '../../common/box';
import {Button} from '../../common/button';
import {usePredictorContext} from '../../../context/predictor-context';
import {useModalContext} from '../../../context/modal-context';
import {PoleTime} from './pole-time-input';
import {gridHideDriverPositions} from '../../../consts/global-vars';
import {useGetDriversQuery} from '../../../graphql/generated';
import {DriversAvatar} from '../../profile-avatar';

interface Props {
  animate: boolean;
  setAnimate: (val: boolean) => void;
}
export const Drivers = (props: Props) => {
  const [showClearButton, setShowClearButton] = useState(false);
  const {data} = useGetDriversQuery();
  const {predictorForm, setPredictorForm} = usePredictorContext();
  const {positionObject} = useModalContext();

  const closeModal = () => {
    props.setAnimate(false);
  };
  const chooseDriver = (driver: string) => {
    predictorForm[positionObject.abbreviatedPosition] = driver;
    setPredictorForm(predictorForm);
    closeModal();
  };
  const clearSelection = () => {
    predictorForm[positionObject.abbreviatedPosition] = '';
    setPredictorForm(predictorForm);
    closeModal();
  };

  const useGetDriverSelectionValue = <T extends string | undefined | null>(
    value: T,
  ): T | undefined | null => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const previousDriverSelectionValue = useGetDriverSelectionValue(
    predictorForm[positionObject.abbreviatedPosition],
  );

  useEffect(() => {
    if (
      !previousDriverSelectionValue &&
      predictorForm[positionObject.abbreviatedPosition].length === 0
    ) {
      setShowClearButton(false);
    }
    if (
      !previousDriverSelectionValue &&
      predictorForm[positionObject.abbreviatedPosition].length > 0
    ) {
      setShowClearButton(true);
    }
  }, [
    positionObject.abbreviatedPosition,
    predictorForm,
    previousDriverSelectionValue,
  ]);
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        justifyContent="space-around"
        my="eight"
        mb={positionObject.abbreviatedPosition === 'Pole' ? 'two' : 'eight'}
      >
        <Box
          m="auto"
          display="grid"
          gridAutoRows="30px"
          gridTemplateColumns="1fr 1fr"
          gridRowGap="25px"
          gridColumnGap="120x"
          mb={2}
        >
          {data?.getDrivers.map((drivers) => {
            return (
              <Box
                key={drivers.fullname.substring(drivers.fullname.length - 3)}
                flex={1}
                margin="auto"
                height="100%"
              >
                {gridHideDriverPositions.indexOf(
                  positionObject.abbreviatedPosition,
                ) !== -1 &&
                Object.entries(predictorForm)
                  .filter(
                    (keyValues) =>
                      gridHideDriverPositions.indexOf(keyValues[0]) !== -1,
                  )
                  .map((filteredKeyVals) => filteredKeyVals.pop())
                  .indexOf(drivers.fullname) !== -1 ? (
                  <Box width="100%" height="100%" margin="auto">
                    <Box>&nbsp;</Box>
                  </Box>
                ) : (
                  <>
                    <DriversAvatar
                      id={drivers.id}
                      name={drivers.fullname}
                      countryId={drivers.countryId}
                      onClick={() => chooseDriver(drivers.fullname)}
                      carNumber={drivers.carNumber}
                    />
                  </>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box flex={1} mt={15}>
        {showClearButton && (
          <Box mb={10}>
            <Button variant="clearButton" onClick={() => clearSelection()}>
              Reset
            </Button>
          </Box>
        )}
        {positionObject.abbreviatedPosition === 'Pole' && (
          <PoleTime
            title={positionObject.position}
            abbreviatedPosition={positionObject.abbreviatedPosition}
            closeModal={() => closeModal()}
          />
        )}
      </Box>
    </Box>
  );
};
