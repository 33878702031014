import React from 'react';
import {ModalComponent} from './modal-template/modal-component';
import {ModalHeader} from './modal-template/modal-containers';
import {useModalContext} from '../../context/modal-context';
import {theme} from '../../theme/theme';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}

export const RulesModal = (props: Props) => {
  const {show, setShowModal, animate, setAnimateModal} = useModalContext();

  const showRulesModal = (val: boolean) => {
    setShowModal({...show, rulesModal: val});
  };
  const animateRulesModal = (val: boolean) => {
    setAnimateModal({...animate, rulesModal: val});
  };

  return (
    <>
      {show.rulesModal ? (
        <ModalComponent
          show={show.rulesModal}
          animate={animate.rulesModal}
          setAnimate={animateRulesModal}
          backgroundColor={props.backgroundColor || 'white'}
          toggle={showRulesModal}
          modalSize={{
            height: theme.sizes.normalModalHeight,
            width: theme.sizes.normalModalWidth,
          }}
        >
          {props.showHeader ? (
            <ModalHeader
              title="Rules"
              animate={animate.rulesModal}
              setAnimate={animateRulesModal}
              show={show.rulesModal}
            />
          ) : null}
        </ModalComponent>
      ) : null}
    </>
  );
};
