// this one seamus TODO
export const endPoint = 'http://localhost:4000/graphql';

export interface SelectionValues {
  [key: string]: string;
}

export const form: SelectionValues = {
  P1: '',
  P2: '',
  P3: '',
  P4: '',
  P5: '',
  P6: '',
  P7: '',
  P8: '',
  P9: '',
  P10: '',
  Pole: '',
  DotD: '',
  Fastest: '',
  MPG: '',
  PoleTime: '',
};
export const gridHideDriverPositions = [
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
  'P9',
  'P10',
];
export const grid = [
  {button: 'predictorButton', type: 'grid', abbreviated: 'P1', title: 'Winner'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P2', title: 'Second'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P3', title: 'Third'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P4', title: 'Fourth'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P5', title: 'Fifth'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P6', title: 'Sixth'},
  {
    button: 'predictorButton',
    type: 'grid',
    abbreviated: 'P7',
    title: 'Seventh',
  },
  {button: 'predictorButton', type: 'grid', abbreviated: 'P8', title: 'Eighth'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P9', title: 'Ninth'},
  {button: 'predictorButton', type: 'grid', abbreviated: 'P10', title: 'Tenth'},
  {
    button: 'predictorButton',
    type: 'event',
    abbreviated: 'Pole',
    title: 'Pole Position',
  },
  {
    button: 'predictorButton',
    type: 'event',
    abbreviated: 'DotD',
    title: 'Driver of the Day',
  },
  {
    button: 'predictorButton',
    type: 'event',
    abbreviated: 'MPG',
    title: 'Most Positions Gained',
  },
  {
    button: 'predictorButton',
    type: 'event',
    abbreviated: 'Fastest',
    title: 'Fastest Lap',
  },
  {
    button: 'largerPredictorButton',
    type: 'race',
    abbreviated: 'Race',
    title: 'Select Race Event',
  },
  {
    button: 'largerPredictorButton',
    type: 'submit',
    abbreviated: 'Submit',
    title: 'Submit',
  },
];
