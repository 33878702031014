import React from 'react';
import Select from 'react-select';
import {useField} from 'formik';
import {ErrorMessageComponent} from './error-message';

interface SelectTypes {
  value: string;
  label: string;
}
interface Props {
  name: string;
  label: string;
  type: string;
  defaultOptionLabel: string;
  options: SelectTypes[];
}

export const SelectDropdown = (props: Props) => {
  const [field, meta, helpers] = useField(props);
  const getCountryNameFromForm = () => {
    const choiceObject = props.options.find(
      (element) => element.value === field.value,
    );
    if (choiceObject) {
      return choiceObject.label;
    }
    return undefined;
  };
  return (
    <>
      <Select
        {...field}
        {...props}
        placeholder={getCountryNameFromForm() || 'Select country'}
        onChange={(selectValue) => helpers.setValue(selectValue.value)}
      />
      <ErrorMessageComponent
        error={meta.error && meta.touched ? meta.error : ''}
      />
    </>
  );
};
