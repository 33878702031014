import React from 'react';
import {Box} from '../reusable-components/common/box';
import {DriverModal} from '../reusable-components/modals/driver-modal/driver-modal';
import {Grid} from './grid';
import {Nav} from './navigation';
import {LoginModal} from '../reusable-components/modals/login-modal';
import {RulesModal} from '../reusable-components/modals/rules-modal';
import {ProfileModal} from '../reusable-components/modals/profile-modal';
import {LoadingWheel} from './loading-wheel';
import {RegisterModal} from '../reusable-components/modals/register-modal';
import {RaceModal} from '../reusable-components/modals/race-modal';
import {useModalContext} from '../context/modal-context';
import {MessageModal} from '../reusable-components/modals/message-modal';

export const App = () => {
  const {isLoading, setShowModal, show, setMessage} = useModalContext();
  const showMessageModal = () => {
    setMessage('hello this is a message that will be types by the thing');
    setShowModal({...show, messageModal: true});
  };

  return (
    <Box>
      <Grid />
      <DriverModal showHeader />
      <LoginModal showHeader />
      <RulesModal showHeader />
      <ProfileModal showHeader />
      <RegisterModal showHeader />
      <RaceModal showHeader />
      {isLoading && <LoadingWheel />}
      <Nav />;
      <MessageModal
        showHeader={false}
        backgroundColor="rgba(255, 177, 10,0.8)"
      />
      <Box mb="ten">
        <button type="button" onClick={() => showMessageModal()}>
          show random message
        </button>
      </Box>
    </Box>
  );
};

/*
TODO STILL IMPLEMENT ? 
  const [clickLocation, setClickLocation] = useState<{x: number; y: number}>({
    x: 0,
    y: 0,
  });
  const [modalLocation, setModalLocation] = useState<{x: number; y: number}>({
    x: 0,
    y: 0,
  });

  const detectScreenOnClick = (e: any) => {
    setClickLocation({x: e.pageX, y: e.pageY});
  };

  useEffect(() => {
    setModalLocation(clickLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverModal]);

    <Box onClick={(e) => detectScreenOnClick(e)}>
  */
