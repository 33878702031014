import React, {useCallback} from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import {Box} from '../reusable-components/common/box';
import {Text} from '../reusable-components/common/text';
import {theme} from '../theme/theme';
import {useModalContext} from '../context/modal-context';
import {
  useResponsiveDetector,
  Responsive,
} from '../context/responsive-detector';
import {ReactComponent as RulesIcon} from '../assests/nav-menu-rules-icon.svg';
import {ReactComponent as LoginIcon} from '../assests/nav-menu-login-icon.svg';
import {ReactComponent as ProfileIcon} from '../assests/nav-menu-profile-icon.svg';
import {ReactComponent as LogoutIcon} from '../assests/nav-menu-logout-icon.svg';
import {useMeContext} from '../context/user';

const NavMenuContainer = styled.div`
  background: #000;
  opacity: 0.45;
  text-shadow: 5px 5px 7px black;
  font-family: roboto;
  font-weight: 200;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;
const SingleNavDiv = styled.div({
  appearance: 'none',
  flex: 1,
  whiteSpace: 'normal',
  '&:hover, :active': {
    backgroundColor: `${theme.colors.navMenu}`,
    cursor: `pointer`,
    transition: 'all 0.7s',
  },
});

export const SvgContainer = styled.div<{height?: string}>`
  max-height: 70%;
  width: auto;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
interface SingleNavMenuItemProps {
  onClick: () => void;
  title: string;
  divDimensions: number;
  svgComponent: (dimensions: string) => JSX.Element;
}
const ImageContainer = (props: SingleNavMenuItemProps) => {
  return (
    <Box justifyContent="center" py={5}>
      <Box
        borderRadius="25px"
        height={`${props.divDimensions}px`}
        width={`${props.divDimensions}px`}
        m="auto"
        border="1px solid white"
        onClick={() => props.onClick()}
        title={props.title}
        position="relative"
      >
        <SvgContainer>{props.svgComponent(`100%`)}</SvgContainer>
      </Box>
    </Box>
  );
};
interface NavTextProps {
  title: string;
  clickEvent: () => void;
}
const NavText = (props: NavTextProps) => {
  return (
    <Box my="three" display="flex" flexDirection="row" justifyItems="center">
      <Box onClick={props.clickEvent} flex={1}>
        <Text
          textAlign="center"
          m="auto"
          fontWeight={300}
          textStyle="large"
          color="white"
        >
          {props.title}
        </Text>
      </Box>
    </Box>
  );
};
export const Nav = () => {
  // SEAMUS TODO ADD TO THEME
  const mobileNavDimensions = 50;
  const device = useResponsiveDetector();
  const {show, setShowModal, setMessage} = useModalContext();
  const {me, setMe} = useMeContext();

  const onLogout = useCallback(() => {
    Cookies.remove('token');
    setMe(null);
  }, [setMe]);

  const openProfileModal = () => {
    if (me) {
      setShowModal({...show, profileModal: true});
      return;
    }
    setMessage('Please log in to view your profile!');
    setShowModal({...show, profileModal: true});
    // setShowModal({...show, messageModal: true, message: 'Please log in  to view your profile'})
  };

  if (device === Responsive.Mobile) {
    return (
      <NavMenuContainer>
        <SingleNavDiv>
          <ImageContainer
            title="Profile"
            onClick={openProfileModal}
            divDimensions={mobileNavDimensions}
            svgComponent={(dimensions) => (
              <ProfileIcon
                fill="white"
                width={dimensions}
                height={dimensions}
              />
            )}
          />
        </SingleNavDiv>
        <SingleNavDiv>
          <ImageContainer
            title="Rules"
            onClick={() => setShowModal({...show, rulesModal: true})}
            divDimensions={mobileNavDimensions}
            svgComponent={(dimensions) => (
              <RulesIcon fill="white" width={dimensions} height={dimensions} />
            )}
          />
        </SingleNavDiv>
        <SingleNavDiv>
          {!me ? (
            <ImageContainer
              title="Login"
              onClick={() => setShowModal({...show, loginModal: true})}
              divDimensions={mobileNavDimensions}
              svgComponent={(dimensions) => (
                <LoginIcon
                  fill="white"
                  width={dimensions}
                  height={dimensions}
                />
              )}
            />
          ) : (
            <ImageContainer
              title="Login"
              onClick={onLogout}
              divDimensions={mobileNavDimensions}
              svgComponent={(dimensions) => (
                <LogoutIcon
                  fill="white"
                  width={dimensions}
                  height={dimensions}
                />
              )}
            />
          )}
        </SingleNavDiv>
      </NavMenuContainer>
    );
  }
  return (
    <NavMenuContainer>
      <SingleNavDiv>
        <NavText
          clickEvent={() => setShowModal({...show, profileModal: true})}
          title="Profile"
        />
      </SingleNavDiv>
      <SingleNavDiv>
        <NavText
          clickEvent={() => setShowModal({...show, rulesModal: true})}
          title="Rules"
        />
      </SingleNavDiv>
      <SingleNavDiv>
        <NavText
          clickEvent={() =>
            !me ? setShowModal({...show, loginModal: true}) : onLogout()
          }
          title={!me ? 'Login' : 'Logout'}
        />
      </SingleNavDiv>
    </NavMenuContainer>
  );
};
