import React from 'react';
import {useModalContext} from '../../context/modal-context';
import {ModalComponent} from './modal-template/modal-component';
import {theme} from '../../theme/theme';
import {ModalHeader} from './modal-template/modal-containers';
import {RaceAvatar} from '../profile-avatar';
import {Box} from '../common/box';
import {useGetAllRacesQuery} from '../../graphql/generated';

interface Props {
  showHeader?: boolean;
  backgroundColor?: string;
}
export const RaceModal = (props: Props) => {
  const {
    show,
    setShowModal,
    animate,
    setAnimateModal,
    // positionObject,
  } = useModalContext();
  const {data, error, loading} = useGetAllRacesQuery();

  const showRaceModal = (val: boolean) => {
    setShowModal({...show, raceModal: val});
  };
  const animateRaceModal = (val: boolean) => {
    setAnimateModal({...animate, raceModal: val});
  };
  return (
    <>
      {show.raceModal ? (
        <ModalComponent
          animate={animate.raceModal}
          show={show.raceModal}
          setAnimate={animateRaceModal}
          backgroundColor={props.backgroundColor}
          toggle={showRaceModal}
          modalSize={{
            height: theme.sizes.normalModalHeight,
            width: theme.sizes.normalModalWidth,
          }}
        >
          {props.showHeader ? (
            <ModalHeader
              animate={animate.raceModal}
              setAnimate={animateRaceModal}
              title="Race"
              show={show.raceModal}
            />
          ) : null}
          <Box flex={1} mt="four" mr="four" p="four">
            {data &&
              data.getRaces &&
              data.getRaces.map(
                ({id, title, description, countryId, eventDateTime}) => {
                  return (
                    <Box p="three">
                      <RaceAvatar
                        title={title}
                        id={id}
                        description={description}
                        countryId={countryId}
                        raceDeadline={new Date(eventDateTime)}
                      />
                    </Box>
                  );
                },
              )}
          </Box>
        </ModalComponent>
      ) : null}
    </>
  );
};
