import {useCallback, useEffect, useState} from 'react';
// import {Breakpoints} from '@studytracks/components/lib/web';
// import {useToast} from '../components/toast';

enum Responsive {
  Mobile,
  Tablet,
  Desktop,
}

enum Breakpoints {
  mobileBreakpointEm = 40,
  tabletBreakpointEm = 52,
  desktopBreakpointEm = 64,
}

const useEventFireCounter = (
  element: Element | Window,
  event: string,
): number => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(0);
  }, [element, event]);

  const onEvent = useCallback(() => {
    setCounter((val) => val + 1);
  }, [setCounter]);

  useEffect(() => {
    element.addEventListener(event, onEvent);

    return () => {
      element.removeEventListener(event, onEvent);
    };
  }, [element, event, onEvent]);

  return counter;
};

const getWindowWithInEm = (): number => {
  const fontSizeStr = window
    .getComputedStyle(document.getElementsByTagName('html')[0], null)
    .getPropertyValue('font-size');

  const fontSizePx = parseFloat(fontSizeStr);

  return window.innerWidth / fontSizePx;
};

const useResponsiveDetector = (): Responsive => {
  useEventFireCounter(window, 'resize');

  const windowWidthEm = getWindowWithInEm();

  useEffect(() => {
    if (windowWidthEm >= 0 && windowWidthEm < 10000) {
      return;
    }
    // eslint-disable-next-line no-console
    console.warn('Unable to detect window size in javascript');
  }, [windowWidthEm]);

  if (windowWidthEm >= Breakpoints.tabletBreakpointEm) {
    return Responsive.Desktop;
  }
  if (windowWidthEm >= Breakpoints.mobileBreakpointEm) {
    return Responsive.Tablet;
  }
  return Responsive.Mobile;
};

export {useResponsiveDetector, Responsive};
