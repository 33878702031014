/* eslint-disable react/button-has-type */
import React from 'react';
import {ApolloProvider} from '@apollo/react-hooks';
import {ThemeProvider} from 'styled-components';
import {theme} from './theme/theme';
import './App.css';
import {ModalProvider} from './context/modal-context';
import {App} from './components/main-container';
import {PredictorProvider} from './context/predictor-context';
import {MeContextProvider} from './context/user';
import {client} from './core/apollo';

export const FormulaPapaya: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <PredictorProvider>
          <ModalProvider>
            <MeContextProvider>
              <App />
            </MeContextProvider>
          </ModalProvider>
        </PredictorProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
