import React from 'react';
import {Box} from '../../common/box';
import {Text} from '../../common/text';

interface Props {
  title?: string;
}

export const DriverModalFooter = (props: Props) => {
  return (
    <Box padding="10px" flex={1} backgroundColor="papaya">
      {props.title === 'Fastest Lap' && (
        <Box>
          <Text textStyle="smallMedium">Fastest Lap &#126; 5 points</Text>
          <Text
            fontWeight="lighter"
            fontStyle="italic"
            pt="5px"
            pl="10px"
            textStyle="small"
          >
            Fastest lap of the race goes to...
          </Text>
        </Box>
      )}
      {props.title === 'Most Positions Gained' && (
        <Box>
          <Text textStyle="smallMedium">
            Most Positions Gained &#126; 5 points
          </Text>
          <Text
            fontWeight="lighter"
            fontStyle="italic"
            pt="5px"
            pl="10px"
            textStyle="small"
          >
            Driver with the highest position delta between the starting grid and
            the race result. For two or more drivers of equal position delta,
            award will go to the highest race finisher.
          </Text>
        </Box>
      )}
      {props.title === 'Driver of the Day' && (
        <Box>
          <Text textStyle="smallMedium">Driver of the Day &#126; 5 points</Text>
          <Text
            fontWeight="lighter"
            fontStyle="italic"
            pt="5px"
            pl="10px"
            textStyle="small"
          >
            The driver outperforming himself, the car, and all others in the
            field - the best race day performance. As voted for by the viewers.
          </Text>
        </Box>
      )}
      {props.title === 'Pole Position' && (
        <Box>
          <Text textStyle="smallMedium">Pole Position Points</Text>
          <Text
            fontWeight="lighter"
            fontStyle="italic"
            pt="5px"
            pl="10px"
            textStyle="small"
          >
            Correct driver &#126; 8 points
          </Text>
          <Text
            fontWeight="lighter"
            fontStyle="italic"
            pt="5px"
            pl="10px"
            textStyle="small"
          >
            Bonus points for correct time (in seconds) &#126; 8 points
          </Text>
        </Box>
      )}
      {props.title !== 'Most Positions Gained' &&
        props.title !== 'Pole Position' &&
        props.title !== 'Driver of the Day' &&
        props.title !== 'Fastest Lap' && (
          <Box>
            <Text textStyle="smallMedium">Top Ten Predictor Points</Text>
            <Text
              fontWeight="lighter"
              fontStyle="italic"
              pt="5px"
              pl="10px"
              textStyle="small"
            >
              Correct &#126; 8 points
            </Text>
            <Text
              fontWeight="lighter"
              fontStyle="italic"
              pt="5px"
              pl="10px"
              textStyle="small"
            >
              One-off &#126; 5 points
            </Text>
            <Text
              fontWeight="lighter"
              fontStyle="italic"
              pt="5px"
              pl="10px"
              textStyle="small"
            >
              Two-off &#126; 2 points
            </Text>
            <Text
              fontWeight="lighter"
              fontStyle="italic"
              pt="5px"
              pl="10px"
              textStyle="small"
            >
              Three-off &#126; 1 points
            </Text>
          </Box>
        )}
    </Box>
  );
};
